import React, { useEffect, lazy, Suspense, useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import logo from "./assets/images/logo-white.png";
import Questions from "./Questions.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faBalanceScaleRight,
  faGrinBeamSweat,
  faChartLine,
  faDollarSign,
  faUsers,
  faUserPlus,
  faRunning,
  faCheckCircle,
  faQuestionCircle,
  faHandPointRight,
  faAngleUp,
  faAngleDown,
  faAngleRight,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { Meta } from "./App.js";
import Footer from "./Footer";
import { showContactFormDialog } from "./utils";
import { globalContext } from "./Context";

const HomeImages1 = lazy(() => import("./HomeImages1.js"));
const HomeImages2 = lazy(() => import("./HomeImages2.js"));
const HomeImages3 = lazy(() => import("./HomeImages3.js"));
const HomeImages4 = lazy(() => import("./HomeImages4.js"));

const ExpandableCard = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="card bg-transparent border-0 mb-4 user-select-none">
      <h4
        className={`h6 card-header rounded d-flex cursor-pointer ${props.classHeader}`}
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          setExpanded(!expanded);
        }}
      >
        <div className="flex-grow-1">{props.label}</div>
        {expanded ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} className="blink" />}
      </h4>
      <div className={expanded ? "d-block fade-in" : "d-none"}>
        <div className="card-body p-2">
          <div className="card-text text-justify">{props.toExpand}</div>
        </div>
      </div>
    </div>
  );
};

const Indicators = (props) => {
  let { dispatch } = useContext(globalContext);
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let indicatorsLeft = document.getElementById("indicatorsLeft");
            let indicatorsRight = document.getElementById("indicatorsRight");
            let indicatorsBottom = document.getElementById("indicatorsBottom");

            indicatorsLeft.classList.add("left-slide-full");
            indicatorsRight.classList.add("right-slide-full");
            indicatorsBottom.classList.add("bottom-slide1");
            indicatorsLeft.classList.remove("d-none");
            indicatorsRight.classList.remove("d-none");
            indicatorsBottom.classList.remove("d-none");
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.8,
      }
    )
  );
  useEffect(() => {
    observer.current.observe(document.getElementById("indicators"));
  }, []);

  return (
    <div className="container py-5 overflow-hidden">
      <div id="indicators">
        <h2 className="text-left mb-4">
          <FontAwesomeIcon icon={faChartLine} /> Technical Indicators
        </h2>
        <div className="justify-content-center row row-cols-1 row-cols-md-2 pb-md-5">
          <div className="col d-none" id="indicatorsLeft">
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Price movements and price crosses"
              toExpand={
                <>
                  You can use the price percent movements as buy and sell signals. This is useful for swing trading. The
                  Price also has rules for crosses with other technical indicators like SMA, EMA, Bollinger Bands,
                  Keltner Channel.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Candle moves and candle patterns"
              toExpand={
                <>
                  Candle moves include open to close/current price, low to high, upper shadow and lower shadow. They are
                  a great way to make a rule with the pure candle movement. <br />
                  You can also use classical candle patterns. The bot implements the following patterns: doji, hammer
                  and inverted hammer, hanging man, shooting star, bullish and bearish engulfs, bullish and bearish
                  harami, morning and evening star.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Simple Moving Average (SMA)"
              toExpand={
                <>
                  Moving averages are an important analytical tool used to identify trends and the potential for a
                  change in an established trend.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Exponential Moving Average (EMA)"
              toExpand={
                <>
                  EMA is a moving average that gives higher weights to recent prices. Thus it reacts more quickly to the
                  changes of the price.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Relative Strength Index (RSI)"
              toExpand={
                <>
                  RSI measures the size of recent price changes to evaluate overbought or oversold conditions. RSI can
                  have a reading from 0 to 100. The 0 corresponds to extreme lows of the price ("oversold"asset) and 100
                  to extreme highs of the price ("overbought" asset).
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Momentum"
              toExpand={
                <>
                  The Momentum indicator determines the strength or weakness of the current price movement. It
                  identifies when the price is moving upward or downward and the force of the movement.
                </>
              }
            />
          </div>
          <div className="col d-none" id="indicatorsRight">
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Moving Average Convergence Divergence (MACD)"
              toExpand={
                <>
                  MACD shows the relationship between two moving averages (fast and slow). The indicator points whether
                  the bullish or bearish movement is strengthening or weakening.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Bollinger Bands® (BB)"
              toExpand={
                <>
                  Bollinger Bands use standard deviation levels of SMA. They help in determining whether the price is
                  moving in its highs or lows. BB work very well in combinations with other instruments.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Keltner Channel"
              toExpand={
                <>
                  The Keltner Channel measures asset volatility. It is similar to Bollinger Bands, but uses EMA and
                  Average True Range , not SMA and standard deviation.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Stochastic Oscillator"
              toExpand={
                <>
                  A Stochastic Oscillator is a momentum indicator that ranges between 0 and 100. The 0 corresponds to
                  extreme lows of the price ("oversold"asset) and 100 to extreme highs of the price ("overbought"
                  asset). It can be more useful in sideways movements rather than strong up and down trends.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Stochastic RSI"
              toExpand={
                <>
                  The Stochastic RSI oscillator uses RSI instead of prices. This makes it more sensitive to price
                  changes and gives it advantage in catching trend reversals. You can use the "crossing" function in
                  your strategies for the bot to hunt these reversals.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Momentum Squeeze"
              toExpand={
                <>
                  The Momentum Squeeze indicator is a combination of Bollinger Bands, Keltner Channels and Momentum.
                  During periods of low volatility, Bollinger Bands narrow and enter inside the Keltner Channel.
                  Bollinger Bands breaking from this squeeze is a strong signal for big price movements.
                </>
              }
            />
          </div>

          <div className="col card bg-transparent border-0 my-4 pb-2 d-none" id="indicatorsBottom">
            <h4 className="h6 p-2 card-header rounded bg-light text-dark">
              <FontAwesomeIcon icon={faQuestionCircle} /> You don't see your favorite indicator...
              <br />
              ...or don't know how to combine them?
            </h4>
            <div className="card-body p-2">
              <a
                className="card-text text-center text-info user-select-none"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  showContactFormDialog(dispatch, "How can we help?", "info@easycryptobot.com");
                }}
              >
                Send us a quick note and we will think of a way!
                <FontAwesomeIcon icon={faCaretRight} className="ml-1" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Optimization = (props) => {
  return (
    <div className="container pb-5">
      <h2 className="">Can your strategy earn you more?</h2>
      <div className="h5 mb-3 mb-md-5">Optimize for best results!</div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-5">
          <p>
            The optimization feature will create thousands variations of your strategy. Then it will auto-backtest them
            all. You will see the 3 best variations in 3 different categories.{" "}
            <b>Want to find out which the categories are?</b>
          </p>
          <a className="btn btn-lg btn-dark my-3 text-uppercase" type="button" href="/free-trial">
            Find out
            <FontAwesomeIcon icon={faAngleRight} className="ml-3 rotate" />
          </a>
        </div>
        <Suspense fallback={<div></div>}>{<HomeImages3 />}</Suspense>
      </div>
    </div>
  );
};
const Backtest = (props) => {
  return (
    <div className="container pt-5 pb-md-5">
      <h2 className="text-light-green">Backtest your ideas in the browser (for FREE)</h2>
      <div className="h5 mb-3 mb-md-5">Unlimited backtests, unlimited strategies</div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-5">
          <p>
            Execute an automatic paper trading. Check how your strategy would have performed if you had run it in the
            past! Get meaningful insights about your strategy - performance, statistics, trade distributions.
          </p>
          <button
            className="btn btn-lg bg-light-green my-3 text-uppercase"
            type="button"
            id="backtestBtn"
            onClick={() => {
              window.history.replaceState("http://easycryptobot.com/backtest", "Easy Crypto Bot", "/");
              window.location.assign("https://app.easycryptobot.com/free-backtest");
            }}
          >
            Start Backtesting
            <FontAwesomeIcon icon={faAngleRight} className="ml-3 rotate" />
          </button>
        </div>

        <div className="col-12 col-md-6 col-lg-7 d-flex justify-content-center" id="backtest">
          <Suspense fallback={<div></div>}>{<HomeImages2 />}</Suspense>
        </div>
      </div>
    </div>
  );
};
const Features = (props) => {
  return (
    <div className="bg-white text-left overflow-hidden px-4 px-md-5 py-md-5">
      <h2 className="display-3 my-3 ">Tired from watching the markets?</h2>
      <h3 className="h3 text-info mb-5">Let EasyCryptoBot handle the trading and enjoy your free time!</h3>

      <div className="justify-content-center row row-cols-1 row-cols-md-2">
        <div className="col">
          <h3 className="h5 mb-3">
            <FontAwesomeIcon icon={faHandPointRight} /> Features
          </h3>
          <div className="row row-cols-1 row-cols-sm-2">
            <div className="col">
              <ul className="list-unstyled ">
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Watchlists and live charts
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Backtest
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Strategy Optimization
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Trading Simulation
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Live Trading
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Manual trading
                </li>
              </ul>
            </div>
            <div className="col">
              <ul className="list-unstyled ">
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Easy to make rules
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Trailing buy
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Stoploss
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Target
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Trailing target
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Time close
                </li>
                {/* <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Conditional trading
                </li> */}
              </ul>
            </div>
          </div>
          <div>
            <a href="/free-backtest" className="btn btn-lg btn-info text-uppercase my-2">
              Free Backtest
              <FontAwesomeIcon icon={faAngleRight} className="ml-3 rotate" />
            </a>
          </div>
          <div>
            <a href="/sign-up" className="btn btn-lg btn-info text-uppercase">
              Sign up
              <FontAwesomeIcon icon={faAngleRight} className="ml-3 rotate" />
            </a>
          </div>
        </div>
        <Suspense fallback={<div></div>}>{<HomeImages4 />}</Suspense>
      </div>
    </div>
  );
};

const Home = (props) => {
  let history = useHistory();

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <div className="page-alt">
        <Meta
          title="EasyCryptoBot - The Crypto Trading Bot That Fits Your Needs"
          metaContent="Don't miss to profit from your ideas - let the bot do the trading! Easy and safe. No limits to your trading with our diverse strategy rules. Free online backtest."
        />
        <section id="crypto-trading-bot">
          <div className="bg-new-dark text-white intro row row-cols-1 row-cols-md-2 mx-0" id="easy-crypto-bot">
            <div className="col p-5">
              <h1 className="major alt py-4 strech">
                <div className="logo d-lg-inline-block d-block">
                  <img src={logo} alt="EasyCryptoBot Crypto Trading Bot" />
                </div>
                Easy Crypto Bot
              </h1>
              <h2 className="text-info my-4 h4">Let your trading ideas work for you</h2>
            </div>
            <Suspense fallback={<div className="col d-none d-md-inline-block"></div>}>{<HomeImages1 />}</Suspense>
          </div>
        </section>
        <section id="crypto-trading-bot-details" className="bg-grad-dark-info pt-5">
          <div className="container">
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 mx-auto text-light">
              <div className="col bg-transp" id="automate-crypto-trading-strategy">
                <div className="card-body">
                  <div className="text-center h2">
                    <FontAwesomeIcon icon={faChartLine} />
                  </div>
                  <h2 className="card-title h5">Automate your trading with a few clicks</h2>
                  <h3 className="h6 font-weight-normal">Trading rules are just plane text with editable fields.</h3>
                </div>
              </div>
              <div className="col bg-transp" id="optimize-crypto-trading-strategy">
                <div className="card-body">
                  <div className="text-center h2">
                    <FontAwesomeIcon icon={faBalanceScaleRight} />
                  </div>
                  <h2 className="card-title h5">Optimize your strategies for best results</h2>
                  <h3 className="h6 font-weight-normal">
                    Not satisfied with the results from your strategy? Let EasyCryptoBot find the best parameters!
                  </h3>
                </div>
              </div>
              <div className="col bg-transp" id="template-crypto-trading-strategies">
                <div className="card-body">
                  <div className="text-center h2">
                    <FontAwesomeIcon icon={faLightbulb} />
                  </div>
                  <h2 className="card-title h5">
                    Out of ideas?
                    <br />
                    Don't know where to start from?
                  </h2>
                  <h3 className="h6 font-weight-normal">
                    Just load a template strategy and optimize it for your preferred coin.
                  </h3>
                </div>
              </div>

              <div className="col bg-transp" id="safe-crypto-bot">
                <div className="card-body">
                  <div className="text-center h2">
                    <FontAwesomeIcon icon={faGrinBeamSweat} />
                  </div>
                  <h2 className="card-title h5">No need to worry about your exchange credentials</h2>
                  <h3 className="h6 font-weight-normal">They are safe and sound living only on your computer.</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-image bg-white position-relative">
          <svg className="lines" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0 0 L100 0 L0 100 Z" />
          </svg>
          <svg className="lines bottom" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M100 0 L0 100 L100 100 Z" />
          </svg>
        </div>

        <section id="crypto-trading-bot-details" className="bg-grad-info-white">
          <div className="container row row-cols-lg-4 row-cols-md-2 row-cols-1 mx-auto text-dark">
            <div className="col bg-transp">
              <div className="card-body">
                <div className="text-center h2">
                  <FontAwesomeIcon icon={faDollarSign} />
                </div>
                <h2 className="card-title h5">Adjustable pricing plans</h2>
                <h3 className="h6 font-weight-normal">Standard and individual plans to fit your trading style.</h3>
              </div>
            </div>
            <div className="col bg-transp">
              <div className="card-body">
                <div className="text-center h2">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <h2 className="card-title h5">Refer to a friend</h2>
                Spread the word and you and your friends get discounts.
              </div>
            </div>
            <div className="col bg-transp">
              <div className="card-body">
                <div className="text-center h2">
                  <FontAwesomeIcon icon={faRunning} />
                </div>
                <h2 className="card-title h5">Demo &amp; Backtest</h2>
                <a
                  href="/"
                  className="text-dark font-weight-bold mt-3"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    history.push(`/free-backtest`);
                  }}
                >
                  Backtest
                </a>{" "}
                your strategy or check our features in{" "}
                <div className="pt-1">
                  <a
                    href="/"
                    className="text-dark font-weight-bold "
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      history.push(`/demo`);
                    }}
                  >
                    Demo mode
                  </a>{" "}
                  without an account!
                </div>
              </div>
            </div>
            <div className="col bg-transp">
              <div className="card-body">
                <div className="text-center h2">
                  <FontAwesomeIcon icon={faUserPlus} />
                </div>
                <h2 className="card-title h5">Ready for a real trial?</h2>

                <button
                  type="button"
                  className="btn btn-sm btn-block btn-dark mt-2 w-md-50"
                  onClick={() => {
                    history.push(`/sign-up`);
                  }}
                >
                  Sign Up
                </button>
                <div className="h6 mt-2">Free of charge</div>
                <div className="">No credit card required</div>
              </div>
            </div>
          </div>
        </section>

        <section id="crypto-trading-bot-features">
          <Features />
        </section>

        <section
          id="crypto-trading-bot-free-backtest"
          className="bg-new-dark intro text-light text-left position-relative"
        >
          <Backtest />
        </section>
        <section id="crypto-trading-bot-indicators" className="bg-new-dark intro text-light position-relative">
          <Indicators />
          <svg className="lines bottom green" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0 0 L0 100 L100 100 Z" />
          </svg>
        </section>
        <section
          id="crypto-trading-bot-optimization"
          className="bg-light-green text-dark text-left overflow-hidden position-relative"
        >
          <Optimization />
        </section>

        <section id="crypto-trading-bot-faq" className="position-relative">
          <svg className="lines green" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0 0 L100 0 L100 100 Z" />
          </svg>

          <Questions classDiv="bg-white text-justify px-3 px-md-5 py-5" classH2="major my-5 py-4 h3 text-center" />
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Home;
export { Questions };
