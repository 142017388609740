import React, { useEffect, useContext, lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Navigation from "./Navigation.js";
import { globalContext } from "./Context.js";
import { GlobalContextProvider } from "./ContextProvider.js";
import { Modal, ModalConfirm } from "./Modal.js";
import Home from "./Home.js";
import { updateContest } from "./utils.js";
import ModalContact from "./ModalContact";

const Manual = lazy(() => import("./Manual.js"));
const Pricing = lazy(() => import("./Pricing.js"));
const Demo = lazy(() => import("./Demo.js"));
const SignUp = lazy(() => import("./SignUp.js"));
const Downloads = lazy(() => import("./Downloads.js"));
const MigrateUserData = lazy(() => import("./MigrateUserData.js"));
const Backtest = lazy(() => import("./Backtest.js"));
const Contest = lazy(() => import("./Contest.js"));

const ScrollToTop = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.path]);

  return "";
};

const Meta = (props) => {
  useEffect(() => {
    document.title = props.title;
    document.querySelector('meta[name="description"]').setAttribute("content", props.metaContent);
  }, []);

  return "";
};

const Loading = (props) => {
  return (
    <div className="page">
      <div className="text-center mx-auto">
        <div className="loading-img-big cursor-help my-5" title="Loading.."></div>
      </div>
    </div>
  );
};

const ManualSuspense = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Manual />
    </Suspense>
  );
};
const PricingSuspense = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Pricing />
    </Suspense>
  );
};

const DemoSuspense = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Demo />
    </Suspense>
  );
};

const SignUpSuspense = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <SignUp />
    </Suspense>
  );
};
const DownloadsSuspense = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Downloads />
    </Suspense>
  );
};
const BacktestSuspense = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Backtest />
    </Suspense>
  );
};
const ContestSuspense = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Contest />
    </Suspense>
  );
};

const routes = [
  { path: "/", name: "Home", Component: Home, exact: true },
  {
    path: "/manual",
    name: "Manual",
    Component: ManualSuspense,
    exact: false,
  },
  {
    path: "/pricing",
    name: "Pricing",
    Component: PricingSuspense,
    exact: true,
  },
  {
    path: "/download-app",
    name: "Apps",
    Component: DownloadsSuspense,
    exact: true,
  },
  {
    path: "/free-backtest",
    name: "Backtests",
    Component: BacktestSuspense,
    exact: true,
  },
  {
    path: "/contest",
    name: "Contests",
    Component: ContestSuspense,
    exact: true,
  },
  { path: "/demo", name: "Demo", Component: DemoSuspense, exact: true, right: true },
  {
    path: "/sign-up",
    name: "Sign Up",
    Component: SignUpSuspense,
    right: true,
    exact: true,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    right: true,
    link: true,
    redirectTo: "https://app.easycryptobot.com",
    exact: true,
  },
];

const MainPage = (props) => {
  let { state, dispatch } = useContext(globalContext);

  useEffect(() => {
    updateContest(dispatch, state.version);
  }, []);

  return (
    <>
      <div id="mainPage" className="">
        <Router>
          <header>
            <Navigation routes={routes} dispatch={dispatch} state={state} />
          </header>
          <main>
              <Switch>
                {routes.map(({ name, path, Component, redirectTo, exact }) =>
                  !redirectTo ? (
                    <Route key={path} path={path} exact={exact}>
                      {({ match }) => (
                        <>
                            <Component />
                          <ScrollToTop path={path} />
                        </>
                      )}
                    </Route>
                  ) : null
                )}
                <Redirect to="/"></Redirect>
              </Switch>
          </main>
        </Router>
      </div>
      <ModalConfirm
        id={"confirmDialog"}
        header={state.confirmDialog.header}
        content={state.confirmDialog.content}
        func={() => {
          if (state.confirmDialog.func()) {
            state.confirmDialog.func();
          }
        }}
        large={state.confirmDialog.large}
        dark={state.confirmDialog.dark}
      />
      <Modal
        id="infoDialog"
        header={state.infoDialog.header}
        content={state.infoDialog.content}
        large={state.infoDialog.large}
        dark={state.infoDialog.dark}
      />
      <ModalContact id={"contactFormDialog"} header={state.contactFormDialog.header} toEmail={state.contactFormDialog.toEmail} />
    </>
  );
};

function App() {
  if (navigator.userAgent.indexOf("easy-crypto-bot") !== -1) {
    return <DownloadsSuspense />;
  }

  return (
    <GlobalContextProvider>
      <MainPage />
    </GlobalContextProvider>
  );
}
export { Meta };
export default App;
