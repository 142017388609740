import React, { useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";

import { isVisibleById } from "./utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import logoReverced from "./assets/images/logo-reverced.png";
import { globalContext } from "./Context.js";

const TopNav = (props) => {
  let { state } = useContext(globalContext);
  return (
    <div className={props.classes}>
      <div id={props.id}>
        <div className="nav-menu bg-new-darker">
          <ul className="navbar-nav">
            {props.routes.map((route) => {
              return (
                <li className="nav-item pull-right" key={route.name}>
                  {route.redirectTo ? (
                    <a
                      className={`text-nowrap nav-link ${route.link ? props.blueButton : ""}`}
                      href="#/"
                      as={NavLink}
                      to={route.path}
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        window.history.replaceState("https://easycryptobot/sign-in", "Easy Crypto Bot", "/");
                        window.location.assign("https://app.easycryptobot.com");
                      }}
                    >
                      {route.name}
                    </a>
                  ) : (
                    <NavLink
                      className={`text-nowrap nav-link ${route.link ? props.blueButton : ""}`}
                      as={NavLink}
                      to={route.path}
                      exact={route.name !== "Manual"}
                      activeClassName="active"
                      onClick={(e) => {
                        document.activeElement.blur();
                      }}
                    >
                      {route.name === "Settings" && (
                        <>
                          <FontAwesomeIcon icon={faCog} />
                          &nbsp;
                        </>
                      )}
                      {route.name}
                      {route.name === "Contest" && state.nextContest && (
                        <span className="bg-light-green align-top text-dark x-small rounded-circle blink-slow px-1 ml-1">
                          N
                        </span>
                      )}
                    </NavLink>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
const Navigation = (props) => {
  useEffect(() => {
    const onBodyClick = (e) => {
      const ids = ["topNavBtn", "topNavBars"];
      if (isVisibleById("topNav")) {
        if (
          ids.indexOf(e.target.id) !== -1 ||
          ids.indexOf(e.target.parentElement.id) !== -1 ||
          ids.indexOf(e.target.parentElement.parentElement.id) !== -1
        ) {
          return;
        }
        let topNav = document.getElementById("topNav");
        topNav.style.display = "none";
        let bars = document.getElementById("topNavBars");
        bars.classList.remove("change");
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, []);
  return (
    <nav className="navbar navbar-expand-md bg-new-darker navbar-dark fixed-top user-select-none" id="topNavbar">
      <div className="nav-header bg-new-darker">
        <NavLink className="navbar-brand p-0" to="/" exact activeClassName="active">
          <img className="pb-1" src={logoReverced} alt="EasyCryptoBot logo" style={{ width: "24px", height: "auto" }} />
          <span id="topNavLogoText">&nbsp;&nbsp;EasyCryptoBot</span>
        </NavLink>
        <button
          className="navbar-toggler border-0"
          type="button"
          id="topNavBtn"
          onClick={(e) => {
            if (isVisibleById("topNav")) {
              let topNav = document.getElementById("topNav");
              topNav.style.display = "none";
              let bars = document.getElementById("topNavBars");
              bars.classList.remove("change");
            } else {
              let topNav = document.getElementById("topNav");
              topNav.style.display = "block";

              let bars = document.getElementById("topNavBars");
              bars.classList.add("change");
            }
          }}
        >
          <div id="topNavBars">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </button>
      </div>
      <TopNav classes="small-nav" id="topNav" routes={props.routes} blueButton="text-info" />
      <TopNav
        classes="big-nav mr-auto"
        id="topNavBig"
        routes={props.routes.filter((el) => !el.right)}
        blueButton="text-info hover-light"
      />
      <TopNav
        classes="big-nav navbar-right pr-3"
        id="topNavBig"
        routes={props.routes.filter((el) => el.right)}
        blueButton="text-info hover-light"
      />
    </nav>
  );
};

export default Navigation;
export { Navigation };
