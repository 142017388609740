import React, { useContext } from "react";
import "./App.css";

import { showTermsAndConditions, showPrivacyPolicy } from "./SignUp.js";
import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = (props) => {
  let { dispatch } = useContext(globalContext);
  return (
    <footer className="footer bg-new-darker text-center text-secondary user-select-none py-2">
      <div>
        Follow us
        <a
          href="https://www.facebook.com/EasyCryptoBot"
          rel="noopener noreferrer"
          target="_blank"
          className="text-info d-inline-block mx-3"
          onClick={(e) => {
            document.activeElement.blur();
          }}
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href="https://twitter.com/EasyCryptoBot"
          rel="noopener noreferrer"
          target="_blank"
          className="text-info d-inline-block"
          onClick={(e) => {
            document.activeElement.blur();
          }}
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
      <div>
        <FontAwesomeIcon icon={faEnvelope} /> info
        <FontAwesomeIcon icon={faAt} />
        easycryptobot.com
      </div>
      <div>
        <a
          href="#/"
          className="d-inline-block text-info text-nowrap mr-2"
          onClick={(e) => {
            e.preventDefault();
            document.activeElement.blur();
            showTermsAndConditions(dispatch);
          }}
        >
          Terms &amp; Conditions
        </a>
        <a
          href="#/"
          className="d-inline-block text-info text-nowrap"
          onClick={(e) => {
            e.preventDefault();
            document.activeElement.blur();
            showPrivacyPolicy(dispatch);
          }}
        >
          Privacy Policy
        </a>
      </div>
      <div>
        Insentico Ltd. &copy; 2018-{new Date().getFullYear()} All rights
        reserved.
      </div>
    </footer>
  );
};
export default Footer;
