import React, { useState, useContext, useEffect, useRef } from "react";
import loading from "./assets/images/loading.gif";
import { Meta } from "./App.js";
import { showInfoDialog } from "./utils.js";
import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { fetchWithRetry } from "./utils.js";
import Footer from "./Footer";

async function signUp(
  setSignUpPhase,
  setErrorMsg,
  name,
  email,
  password,
  refCode
) {
  setSignUpPhase(1);
  setErrorMsg(null);

  fetchWithRetry(
    `https://api2.easycryptobot.com/register.php`,
    "post",
    {
      name: name,
      email: email,
      password: password,
      refCode: refCode,
    },
    (json) => {
      //console.log(json);
      setSignUpPhase(2);
    },
    () => {
      setSignUpPhase(0);
      setErrorMsg(
        "Unable to register! A user with that email may already exist."
      );
    },
    {},
    1
  );
}

function showTermsAndConditions(dispatch) {
  import("./terms-and-conditions.js").then((component) => {
    showInfoDialog(
      dispatch,
      <span className="text-info">Terms &amp; Conditions</span>,
      component.default,
      true
    );
  });
}

function showPrivacyPolicy(dispatch) {
  import("./privacy-policy.js").then((component) => {
    showInfoDialog(
      dispatch,
      <span className="text-info">Privacy Policy</span>,
      component.default,
      true
    );
  });
}

const SignUp = (props) => {
  const [freeDays, setFreeDays] = useState(7);
  const [t, setT] = useState(false);
  const [end, setEnd] = useState();

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchWithRetry(
      `https://api2.easycryptobot.com/free-trial-days.php`,
      "get",
      null,
      (json) => {
        //console.log(json);
        if (componentIsMounted) {
          setFreeDays(json.d);
          setEnd(json.end);
          setT(true);
        }
      },
      () => {},
      {},
      2
    );
  }, []);
  
  return (
    <>
      {!t && (
        <Meta
          title={`EasyCryptoBot - ${freeDays} Days Full Access for Free`}
          metaContent={`Try all features ${freeDays} Days for free then continue on the free plan or upgrade your plan. No credit card required. No automatic subscriptions.`}
        />
      )}
      {t && (
        <Meta
          title={`EasyCryptoBot - ${freeDays} Days Full Access for Free`}
          metaContent={`Try all features ${freeDays} Days for free then continue on the free plan or upgrade your plan. No credit card required. No automatic subscriptions.`}
        />
      )}
      <div className="page-alt bg-white pb-5">
        <h1 className="major strech mx-sm-5 py-4">
          <FontAwesomeIcon icon={faUserPlus} /> Sign up
        </h1>
        <h2 className="mt-4">No credit card required!</h2>
        <SignUpForm title freeDays={freeDays} end={end} />
      </div>
      <Footer />
    </>
  );
};
const SignUpForm = (props) => {
  let { dispatch } = useContext(globalContext);
  const [signUpPhase, setSignUpPhase] = useState(0);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [refCode, setRefCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  return (
    <>
      {signUpPhase === 0 && (
        <div className="d-flex">
          <div className="card card-signin col-sm-9 col-md-7 col-lg-5 mx-auto mt-4">
            <div className="card-body">
              {props.title && (
                <>
                  {props.end && (
                    <div className="scratched text-danger">
                      7 Days Full Access
                    </div>
                  )}
                  <h3 className="text-center">
                    {props.freeDays} Days Full Access
                  </h3>
                  {props.end && (
                    <div className="text-danger">
                      for accounts created in {props.end}
                    </div>
                  )}
                  <h3 className="h4 card-title text-center mt-2">
                    Free plan after
                  </h3>
                </>
              )}
              <form
                className="form-signin"
                id="signInForm"
                onSubmit={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  showInfoDialog(dispatch, "EasyCryptoBot is at capacity right now!", "Please try again later.")
                  // signUp(
                  //   setSignUpPhase,
                  //   setErrorMsg,
                  //   name,
                  //   username,
                  //   password,
                  //   refCode
                  // );
                }}
              >
                <div className="form-label-group mb-3">
                  <input
                    type="text"
                    id="signUpName"
                    className="form-control"
                    placeholder="Your Name"
                    required
                    autoComplete="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="form-label-group mb-3">
                  <input
                    type="email"
                    id="signUpEmail"
                    className="form-control"
                    placeholder="Email Address"
                    required
                    autoComplete="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>

                <div className="form-label-group mb-3">
                  <input
                    type="password"
                    id="signUpPassword"
                    className="form-control"
                    placeholder="Password"
                    autoComplete="off"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="form-label-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ref Code"
                    autoComplete="off"
                    value={refCode}
                    onChange={(e) => setRefCode(e.target.value)}
                  />
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="termsAccepted"
                    className="custom-control-input"
                    checked={termsAccepted}
                    onChange={(e) => {
                      setTermsAccepted(e.target.checked);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="termsAccepted"
                  >
                    Accept
                    <a
                      href="#/"
                      className="ml-1 text-info"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        showTermsAndConditions(dispatch);
                        return;
                      }}
                    >
                      Terms &amp; Conditions
                    </a>
                  </label>
                </div>
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    id="privacyAccepted"
                    className="custom-control-input"
                    checked={privacyAccepted}
                    onChange={(e) => {
                      setPrivacyAccepted(e.target.checked);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="privacyAccepted"
                  >
                    Accept
                    <a
                      href="#/"
                      className="ml-1 text-info"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        showPrivacyPolicy(dispatch);
                        return;
                      }}
                    >
                      Privacy Policy
                    </a>
                  </label>
                </div>

                <button
                  id="signUpBtn"
                  className="btn btn-info btn-block text-uppercase"
                  type="submit"
                  disabled={
                    termsAccepted &&
                    privacyAccepted &&
                    name &&
                    username &&
                    password
                      ? ""
                      : "disabled"
                  }
                >
                  Create account
                </button>
                {errorMsg && <h5 className="text-danger mt-4">{errorMsg}</h5>}
              </form>
            </div>
          </div>
        </div>
      )}
      {signUpPhase === 1 && (
        <>
          <div
            className="loading-img cursor-help mt-5 mb-3"
            title="Setting-up.."
          >
            <img src={loading} alt="EasyCryptoBot " />
          </div>
          <h2 className="h5 loading">Setting-up your account</h2>
        </>
      )}
      {signUpPhase === 2 && (
        <>
          <h2 className="h4 mt-5 mx-sm-5 pt-5">Your account was created!</h2>
          <h3 className="mb-3 mx-sm-5">
            Please check your email for a verification link.
          </h3>
          <div className="text-secondary mb-1 mx-sm-3">
            Your account will become active after you verify your email via the
            verification link.
          </div>
          <div className="text-secondary mb-5 mx-sm-3">
            If you can't find the verification email, please check your spam
            folder.
          </div>
        </>
      )}
    </>
  );
};

export { SignUp, SignUpForm, showTermsAndConditions, showPrivacyPolicy };
export default SignUp;
