//EasyCryptoBot Copyright (C) 2020 Incentico Ltd.

async function fetchWithRetry(
  endpoint,
  methodType,
  bodyParams,
  okFunc = null,
  errorFunc = null,
  headers = {},
  maxRetries = 3,
  retry = 1
) {
  let doRetry = false;
  let response = null;
  try {
    let params = {
      method: methodType,
      headers: {
        ...headers,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    if (bodyParams) {
      params.body = JSON.stringify(bodyParams);
    }

    response = await fetch(endpoint, params);
    if (response.status === 200 || response.status === 201) {
      const json = await response.json();
      if (okFunc) {
        try {
          okFunc(json);
        } catch (e) {}
      }
      return;
    } else {
      doRetry = true;
    }
  } catch (e) {
    console.log(e);
    doRetry = true;
  }
  if (doRetry) {
    if (retry < maxRetries) {
      await sleep(1000);
      fetchWithRetry(
        endpoint,
        methodType,
        bodyParams,
        okFunc,
        errorFunc,
        headers,
        maxRetries,
        retry + 1
      );
      return;
    }
  }

  if (errorFunc) {
    errorFunc(response);
  }
}

function showInfoDialog(dispatch, header, content, large, dark) {
  dispatch({
    type: "infoDialog",
    payload: { header: header, content: content, large: large, dark: dark },
  });
  if (!isVisibleById("infoDialog")) {
    let infoDialog = document.getElementById("infoDialog");
    infoDialog.classList.add("show");
    infoDialog.setAttribute("aria-hidden", "false");
    infoDialog.setAttribute("style", "display: block");
    infoDialog.focus();

    let mainPage = document.getElementById("mainPage");
    mainPage.classList.add("modal-open");
    mainPage.setAttribute("style", "opacity: 0.3");

    let body = document.body;
    body.classList.add("modal-open");
  }
}

function showConfirmDialog(dispatch, header, content, func, large, dark) {
  dispatch({
    type: "confirmDialog",
    payload: {
      header: header,
      content: content,
      func: func,
      large: large,
      dark: dark,
    },
  });

  if (!isVisibleById("confirmDialog")) {
    let infoDialog = document.getElementById("confirmDialog");
    infoDialog.classList.add("show");
    infoDialog.setAttribute("aria-hidden", "false");
    infoDialog.setAttribute("style", "display: block");

    infoDialog.focus();

    let mainPage = document.getElementById("mainPage");
    mainPage.classList.add("modal-open");
    mainPage.setAttribute("style", "opacity: 0.3");

    let body = document.body;
    body.classList.add("modal-open");
  }
}
function showContactFormDialog(dispatch, header, toEmail) {
  dispatch({
    type: "contactFormDialog",
    payload: { header: header, toEmail: toEmail },
  });
  if (!isVisibleById("modalContact")) {
    let modalDialog = document.getElementById("modalContact");
    modalDialog.classList.add("show");
    modalDialog.setAttribute("aria-hidden", "false");
    modalDialog.setAttribute("style", "display: block");

    modalDialog.focus();

    let mainPage = document.getElementById("mainPage");
    mainPage.classList.add("modal-open");
    mainPage.setAttribute("style", "opacity: 0.3");

    let body = document.body;
    body.classList.add("modal-open");
  }
}
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function isVisibleById(id) {
  let element = window.document.getElementById(id);

  return element ? window.getComputedStyle(element).display !== "none" : false;
}

async function updateContest(dispatch, version) {
  while (true) {
    // eslint-disable-next-line no-loop-func
    await fetchWithRetry(
      "https://api2.easycryptobot.com/contests.php",
      "get",
      null,
      (json) => {
        if (json.contests.length > 0) {
          json.lastContest = json.contests[json.contests.length - 1];
        }
        dispatch({
          type: "contests",
          payload: json,
        });
      }
    );
    await sleep(1000 * 60 * 60 * 5);
  }
}
async function getContests(dispatch, setContestLoaded, componentIsMounted) {
  const endpoint = "https://api2.easycryptobot.com/contests.php";
  return new Promise(async (resolve) => {
    await fetchWithRetry(
      endpoint,
      "get",
      null,
      async (json) => {
        try {
          if (json) {
            if (json.contests.length > 0) {
              json.lastContest = json.contests[json.contests.length - 1];
            }
            dispatch({
              type: "contests",
              payload: json,
            });
            if (componentIsMounted.current) {
              setContestLoaded(true);
            }
          }
          resolve();
        } catch (e) {
          resolve();
        }
      },
      () => {
        resolve();
      }
    );
  });
}
async function loadRanking(month, page, dispatch) {
  const endpoint = `https://api2.easycryptobot.com/contest-executions.php?m=${month}&p=${page}`;
  return new Promise(async (resolve) => {
    await fetchWithRetry(
      endpoint,
      "get",
      null,
      async (json) => {
        try {
          if (json && json.executions) {
            dispatch({
              type: "contestRanking",
              payload: json,
            });
          }
          resolve();
        } catch (e) {
          resolve();
        }
      },
      () => {
        resolve();
      }
    );
  });
}
async function getContestWinners(month, dispatch) {
  let winners = [];
  const endpoint = `https://api2.easycryptobot.com/contest-executions.php?m=${month}&p=0`;

  let promise = new Promise(async (resolve) => {
    await fetchWithRetry(
      endpoint,
      "get",
      null,
      async (json) => {
        try {
          if (json && json.executions) {
            resolve(json.executions);
            return;
          }
          resolve();
        } catch (e) {
          resolve();
        }
      },
      () => {
        resolve();
      }
    );
  });
  let result = await promise;
  if (result) {
    result.forEach((execution) => {
      if (winners.length === 3 || execution.result < 0) {
        return;
      }
      let userAdded = winners.find((winner) => {
        if (execution.strategy.originalUserId) {
          return (
            winner.strategy.originalUserId === execution.strategy.originalUserId
          );
        } else {
          return winner.strategy.nickname === execution.strategy.nickname;
        }
      });
      if (!userAdded) {
        winners.push(execution);
      }
    });
  }
  dispatch({
    type: "contestWinners",
    payload: winners,
  });
}
export {
  showInfoDialog,
  showConfirmDialog,
  showContactFormDialog,
  sleep,
  fetchWithRetry,
  isVisibleById,
  updateContest,
  loadRanking,
  getContests,
  getContestWinners,
};
